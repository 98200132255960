import React, { useEffect } from "react"
import Layout from "../components/layout"
import axios from "axios"
import "./support.scss"
import settings from "../settings"

const SuccessPage = () => {
  useEffect(() => {
    axios({
      method: "post",
      url: settings.API_URL + "api/payment/verify",     
    }).then(data => {        
        if(data.data.validSuccess == "false"){
            window.location.href = "https://iwant2explore.com";
        }
      }).catch(err => {
        alert(err)
      })
  })
  return (
    <Layout>
      <div className="contentalign">
        <h3 className='heading'>Thank you! for purchasing the itineary</h3>
        <p>We have sent you an E-mail with your Itineary PDf.</p>
        <p>Happy Journey :)</p>  
       </div>
    </Layout>
    )
}

export default SuccessPage
